import { Breadcrumb, Card, Col, Container, Row } from "react-bootstrap";
import { AppDispatch, RootState } from "../../Store/Store";
import { useDispatch, useSelector } from "react-redux";
import ChangePasswordForm from "./form";
import { useChangePasswordMutation } from "../../Store/Authorization/Authorization.service";
import { setMessage } from "../../Store/Notification/Notification.slice";
import { ApiError } from "../../Services/BaseApi";
import { useNavigate } from "react-router-dom";

function Profile() {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const token = useSelector((state: RootState) => state.authorization.token)

    const [changePassword, { isLoading, isSuccess }] = useChangePasswordMutation()

    const handleChangePassword = async (credentials: { currentPassword: string, newPassword: string, confirmPassword: string }) => {
        try {
            await changePassword({
                currentPassword: credentials.currentPassword,
                newPassword: credentials.newPassword,
            }).unwrap()
            dispatch(setMessage({
                message: 'Password changed successfully',
                variant: 'success',
            }))
        } catch (err) {
            dispatch(setMessage({
                message: (err as ApiError).data?.error,
                variant: 'danger',
            }))
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/')}>Dash</Breadcrumb.Item>
                        <Breadcrumb.Item active>Profile</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>Profile</h1>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    {token?.user.email}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>Change Password</Card.Header>
                        <Card.Body>
                            {isSuccess ? <p>Password changed successfully</p> : <ChangePasswordForm
                                className="mx-auto"
                                disabled={isLoading}
                                handleSubmit={handleChangePassword}
                            />}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Profile;
