interface IConfig {
    isDev(): boolean
    SERVER_BASE_URL: string
    ACCOUNT_BASE_URL: string
}

export const Config: IConfig = {
    isDev,
    SERVER_BASE_URL: process.env.NODE_ENV === 'development' ? 'http://localhost:3002' : 'https://apiadminv3.esavvy.io',
    //SERVER_BASE_URL: process.env.NODE_ENV === 'development' ? 'https://apiadminv3.esavvy.io' : 'https://apiadminv3.esavvy.io',
    ACCOUNT_BASE_URL: process.env.NODE_ENV === 'development' ? 'http://localhost:3200' : 'https://accounts.esavvy.io',
}

function isDev(): boolean {
    return process.env.NODE_ENV === 'development'
}