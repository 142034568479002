import React from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"

interface IChangePasswordFormProps {
    handleSubmit(credentials: { currentPassword: string, newPassword: string, confirmPassword: string }): void
    disabled: boolean
    className?: string
}

const ChangePasswordForm: React.FC<IChangePasswordFormProps> = (props: IChangePasswordFormProps) => {

    const initState = {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    }

    const [initialValues] = React.useState(initState)

    const onSubmit = (values: {
        currentPassword: string
        newPassword: string
        confirmPassword: string
    }) => {
        props.handleSubmit(values)
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        // reValidateMode: "onChange",
        defaultValues: initialValues
    })

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className={props.className}>
            <Form.Group className="mb-3" controlId="formBasicCurrentPassword">
                <Form.Control
                    type="password"
                    placeholder="Current Password"
                    {...register("currentPassword", { required: "Current Password is required" })}
                />
                {errors.currentPassword && (
                    <Form.Text className="text-danger">
                        {errors.currentPassword.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicNewPassword">
                <Form.Control
                    type="password"
                    placeholder="New Password"
                    {...register("newPassword", { required: "New Password is required" })}
                />
                {errors.newPassword && (
                    <Form.Text className="text-danger">
                        {errors.newPassword.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    {...register("confirmPassword", {
                        required: "Confirm Password is required",
                        validate: (value, formValues) => 
                            value === formValues.newPassword || "Passwords do not match"
                    })}
                />
                {errors.confirmPassword && (
                    <Form.Text className="text-danger">
                        {errors.confirmPassword.message}
                    </Form.Text>
                )}
            </Form.Group>

            <div className="d-grid gap-2">
                <Button
                    type='submit'
                    variant="primary"
                    disabled={!isValid || props.disabled}
                >Change Password</Button>
            </div>
        </Form>

    )
}

export default ChangePasswordForm