import { IMerchant, IMerchantCategory, IMerchantTerms } from "@esavvynpm/types"
import { baseSplitApi } from "../../Services/BaseApi"
import { API_URLS } from "../../Services/api-urls"
import { MerchantAddPayload, MerchantCategoryUpdatePayload, MerchantUpdatePayload } from "./Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        listMerchantsComplete: build.query<IMerchant[], void>({
            query: () => API_URLS.merchants,
            providesTags: ["ListMerchants"],
        }),
        listMerchantsCategories: build.query<IMerchantCategory[], void>({
            query: () => API_URLS.listMerchantsCategories,
            providesTags: ["ListMerchantCategories"],
        }),
        getMerchantsCategory: build.query<IMerchantCategory, string>({
            query: (categoryId) => API_URLS.merchantsCategory.replace(':categoryId', categoryId),
            providesTags: ["GetMerchantCategory"],
        }),
        addMerchantCategory: build.mutation<void, MerchantCategoryUpdatePayload>({
            query: (body) => ({
                url: API_URLS.listMerchantsCategories,
                method: 'POST',
                body,
            }),
            invalidatesTags: ["ListMerchantCategories"]
        }),
        updateMerchantCategory: build.mutation<void, { categoryId: string, body: MerchantCategoryUpdatePayload }>({
            query: ({ categoryId, body }) => ({
                url: API_URLS.merchantsCategory.replace(':categoryId', categoryId),
                method: 'PUT',
                body,
            }),
            invalidatesTags: ["ListMerchantCategories", "GetMerchantCategory"]
        }),
        getMerchant: build.query<IMerchant, string>({
            query: (merchantId) => API_URLS.merchantId.replace(':merchantId', merchantId),
            providesTags: ["GetMerchant"]
        }),
        getMerchantTerms: build.query<IMerchantTerms, string>({
            query: (merchantId) => `${API_URLS.merchantIdTerms}?merchantId=${merchantId}`,
            providesTags: ["GetMerchantTerms"]
        }),
        updateMerchantTerms: build.mutation<void, {
            merchantId: string, body: {
                geoInclude?: string[]
                geoExclude?: string[]
                terms?: string
                isActive?: boolean
            }
        }>({
            query: ({ merchantId, body }) => ({
                url: API_URLS.merchantIdTermsId.replace(':termId', merchantId),
                method: 'PUT',
                body,
            }),
            invalidatesTags: ["GetMerchantTerms"]
        }),
        addMerchant: build.mutation<void, MerchantAddPayload>({
            query: (body) => ({
                url: API_URLS.merchants,
                method: 'POST',
                body,
            }),
            invalidatesTags: ["ListMerchants"]
        }),
        updateMerchant: build.mutation<void, { merchantId: string, body: MerchantUpdatePayload }>({
            query: ({ merchantId, body }) => ({
                url: API_URLS.merchantId.replace(':merchantId', merchantId),
                method: 'PUT',
                body,
            }),
            invalidatesTags: ["ListMerchants", "GetMerchant"]
        }),
        deleteMerchant: build.mutation<void, string>({
            query: (merchantId) => ({
                url: API_URLS.merchantId.replace(':merchantId', merchantId),
                method: 'DELETE',
            }),
            invalidatesTags: ["ListMerchants", "GetMerchant"]
        }),
        listNetworks: build.query<string[], void>({
            query: () => API_URLS.networks
        }),
    }),
    overrideExisting: false,
})

export const {
    useDeleteMerchantMutation,
    useGetMerchantQuery,
    useAddMerchantMutation,
    useListMerchantsCategoriesQuery,
    useAddMerchantCategoryMutation,
    useUpdateMerchantCategoryMutation,
    useGetMerchantsCategoryQuery,
    useGetMerchantTermsQuery,
    useUpdateMerchantTermsMutation,
    useUpdateMerchantMutation,
    useListMerchantsCompleteQuery,
    useListNetworksQuery,
} = api