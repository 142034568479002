import { AuthEsavvyBody, IOAuth2Token } from "@esavvynpm/types"
import { baseSplitApi } from "../../Services/BaseApi"
import { API_URLS } from "../../Services/api-urls"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        signInWithEsavvy: build.mutation<IOAuth2Token, AuthEsavvyBody>({
            query: (credentials) => ({
                url: API_URLS.oauth2GetToken,
                method: 'POST',
                body: `grant_type=password&username=${credentials.email}&password=${credentials.password}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }),
        }),
        changePassword: build.mutation<void, { currentPassword: string, newPassword: string }>({
            query: ({ currentPassword, newPassword }) => ({
                url: API_URLS.changePassword,
                method: 'POST',
                body: {
                    currentPassword,
                    newPassword,
                }
            }),
        }),
    }),
    overrideExisting: false,
})

export const {
    useSignInWithEsavvyMutation,
    useChangePasswordMutation,
} = api