export const API_URLS = {
    oauth2GetToken: '/oauth-admin/token',
    changePassword: '/accounts/change-password',
    listUsers: '/users',
    userId: '/users/:userId',
    userIdInfluencer: '/users/:userId/influencer',
    userIdHistory: '/users/:userId/history',
    userIdRecommendations: '/users/:userId/recommendations',
    merchants: '/merchants',
    listMerchantsCategories: '/merchant-categories',
    merchantsCategory: '/merchant-categories/:categoryId',
    merchantId: '/merchants/:merchantId',
    merchantIdTerms: '/merchant-terms',
    merchantIdTermsId: '/merchant-terms/:termId',
    statsIncomeByType: '/stats/income-by-type',
    statsFeed: '/stats/feed',
    statsAccounting: '/stats/accounting',
    feedItems: '/feed',
    regions: '/regions',
    funnelStats: '/stats/funnel',
    anos: '/anos',
    anoId: '/anos/:anoId',
    invoices: '/invoices',
    invoiceId: '/invoices/:invoiceId',
    rges: '/rges',
    rgeId: '/rges/:rgeId',
    txnImportBooking: '/import-ano/booking',
    recommendations: '/recommendations',
    networks: '/networks',
    cjProductSearchParams: '/networks/cj/product-search-params',
    cjProductSearchParamsId: '/networks/cj/product-search-params/:id',
    cjProducts: '/networks/cj/products',
    cjMerchants: '/networks/cj/merchants',
    cjTypes: '/networks/cj/types',
    cjPromotionTypes: '/networks/cj/promotion-types',
    cjLanguages: '/networks/cj/languages',
    cjCategories: '/networks/cj/categories',
    rakutenProducts: '/networks/rakuten/products',
    rakutenCategoryList: '/networks/rakuten/categories',
    rakutenMerchants: '/networks/rakuten/merchants',
    rakutenProductSearchParams: '/networks/rakuten/product-search-params',
    rakutenProductSearchParamsId: '/networks/rakuten/product-search-params/:id',
    feedComposition: '/feed-compositions',
    feedCompositionId: '/feed-compositions/:id',
}