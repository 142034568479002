import { AppsType, IFeedComposition, IFeedItem, IFeedRegionProduct, IRegion, IScheduledFeedProduct, PaginationResult } from "@esavvynpm/types"
import { baseSplitApi } from "../../Services/BaseApi"
import { API_URLS } from "../../Services/api-urls"
import { IScheduleFeedProductPublishRequest, PaginatedQueryPayload } from "../Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        listFeedItems: build.query<PaginationResult<IFeedItem>, { page: number, app: AppsType }>({
            query: ({ page, app }) => {
                let queryParams = `?page=${page}&app=${app}`;
                return API_URLS.feedItems + queryParams
            },
        }),
        listRegions: build.query<(IRegion & { _id: string })[], void>({
            query: () => API_URLS.regions,
        }),
        listFeedComposition: build.query<IFeedComposition[], void>({
            query: () => API_URLS.feedComposition,
            providesTags: ["ListFeedComposition"]
        }),
        getFeedComposition: build.query<IFeedComposition, string>({
            query: (compositionId) => API_URLS.feedCompositionId.replace(':id', compositionId),
            providesTags: ["ListFeedComposition"]
        }),
        addFeedComposition: build.mutation<IFeedComposition, Omit<IFeedComposition, "_id">>({
            query: (body) => ({
                url: API_URLS.feedComposition,
                method: 'POST',
                body,
            }),
            invalidatesTags: ["ListFeedComposition"]
        }),
        updateFeedComposition: build.mutation<IFeedComposition, {
            id: string
            body: Partial<IFeedComposition>
        }>({
            query: ({ id, body }) => ({
                url: API_URLS.feedCompositionId.replace(':id', id),
                method: 'PUT',
                body,
            }),
            invalidatesTags: ["ListFeedComposition"]
        }),
        deleteFeedComposition: build.mutation<void, string>({
            query: (compositionId) => ({
                url: API_URLS.feedCompositionId.replace(':id', compositionId),
                method: 'DELETE',
            }),
            invalidatesTags: ["ListFeedComposition"]
        }),
    }),
    overrideExisting: false,
})

export const {
    useListFeedItemsQuery,
    useListRegionsQuery,
    useListFeedCompositionQuery,
    useAddFeedCompositionMutation,
    useUpdateFeedCompositionMutation,
    useDeleteFeedCompositionMutation,
    useGetFeedCompositionQuery,
} = api